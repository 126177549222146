import { useEffect, useState, memo, useContext } from "react";
import filter_icon from "../assets/filter_icon.png";
import "./FilterBar.scss";
import AllJobFiltersContainerMobile from "../all-job-filters-container-mobile/AllJobFiltersContainerMobile";
import AllJobFiltersContainerDesktop from "../all-job-filters-container-desktop/AllJobFiltersContainerDesktop";
import { JobSearchOptionsModel } from "../tp-core-types/JobSearchOptionsModel";
import { useNavigate } from "react-router-dom";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import application_icon from "../assets/application_icon.svg"


type Props = {
  buttontext: string;
  professionId: string | null;
  specialtyIds: string | null;
  stateIds: string | null;
  cityIds: string | null;
  shiftCategoryId: string | null;
  numberOfShifts: string | null;
  filterOptions: JobSearchOptionsModel | null;
  showStartApplicationLink?: boolean;
};

export default memo(function FilterBar(props: Props) {
  const [showStartApplicationLink, setShowStartApplicationLink] = useState<boolean>(props.showStartApplicationLink ?? false);
  const [buttonText, setButtonText] = useState<string>(props.buttontext);
  const userInfoModel = useContext(UserInfoModel);
  const navigate = useNavigate();
  function isEmpty(value : string | null) {
    return value === null || value.trim() === '';
  }
  useEffect(() => {
    if(isEmpty(props.specialtyIds) && isEmpty(props.stateIds) && isEmpty(props.cityIds) && isEmpty(props.numberOfShifts) && isEmpty(props.professionId)){
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }
  }, []);
  useEffect(() => {
      if (userInfoModel.token !== "" && userInfoModel.userInfo.firstName !== "") { //checking first name so the start application link is hidden until the userInfo call comes back
          getCompletedSteps()
      }
  }, [navigate, userInfoModel.token, userInfoModel.userInfo]);

  const getCompletedSteps = async () => {
      const steps = await TPCoreAPI.getCompletedHCPApplicationSteps();
      setShowStartApplicationLink(!userInfoModel.userInfo.applicationComplete && steps.length === 0)
  }

  const [isMobile, setIsMobile] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  function toggleFilters() {
    setShowFilters(!showFilters)
  }
  useEffect(() => {
    if (showFilters) setButtonText("Hide Filters")
      else setButtonText("Show Filters")
  },[showFilters])

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 800px)");

    const handleMobileChange = (e: {
      matches: boolean | ((prevState: boolean) => boolean);
    }) => {
      setIsMobile(e.matches);
    };

    mobileMediaQuery.addEventListener("change", handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);
  }, [setIsMobile]);

  return (
    <div id="FilterBar">
      <div className="search-filter-area">
        <div className="findAJob">Find A Job</div>
        <div className="header-right-side">
          { !isMobile && showStartApplicationLink &&
            <div className="link-container" data-testid="link-container" onClick={(e)=>{e.stopPropagation(); navigate('/apply-now')}}>
                <img className="app-icon" src={application_icon} alt="app"></img>
                <div className="start-link">Start TRS Application</div>
            </div>
          }
          <div
            id="search-filters-button"
            data-testid="search-filters-button"
            className="allFiltersButton"
            onClick={toggleFilters}
          >
            <img
              src={filter_icon}
              id="imgFilter"
              alt=""
              className="filterSearchIcon"
            ></img>
            <span>{buttonText}</span>
          </div>
        </div>
      </div>

      {showFilters && (
        <>
          {isMobile && (
            <div
              className="mobileFiltersContainer"
              data-testid="mobile-filters-container"
            >
              <AllJobFiltersContainerMobile
                professionId={props.professionId}
                specialtyIds={props.specialtyIds}
                stateIds={props.stateIds}
                cityIds={props.cityIds}
                shiftCategoryId={props.shiftCategoryId}
                numberOfShifts={props.numberOfShifts}
                onToggle={toggleFilters}
                showFiltersContainer={showFilters}
              ></AllJobFiltersContainerMobile>
            </div>
          )}
          {!isMobile && (
            <div
              className="desktopFiltersContainer"
              data-testid="desktop-filters-container"
            >
              <AllJobFiltersContainerDesktop
                titleText=""
                professionId={props.professionId}
                specialtyIds={props.specialtyIds}
                stateIds={props.stateIds}
                cityIds={props.cityIds}
                shiftCategoryId={props.shiftCategoryId}
                numberOfShifts={props.numberOfShifts}
                onToggle={toggleFilters}
                showFiltersContainer={showFilters}
              ></AllJobFiltersContainerDesktop>
            </div>
          )}
        </>
      )}
    </div>
  );
})
