export type TimesheetShiftSaveResult = {
    shiftId: number;
};

export type GetHcpActiveContractsWithTimesheetsResult = {
    contractNum: number,
    startDate: string,
    endDate: string,
    facilityName: string,
    city: string,
    state: string,
    timesheets: Array<TimesheetPayPeriod>
}

export type TimesheetPayPeriod = {
    timesheetId: number,
    startDate: string,
    endDate: string,
    isLocked: boolean,
    submittedDate: string | null
}

export class TimesheetShiftData {
    public shiftId: number | null;
    public shiftDate: Date | null;
    public shiftTypeId: number;
    public startTime: Date | null;
    public endTime: Date | null;
    public unit: string;
    public lunch: number | null;
    public timesheetId: number;

    constructor(json: any) {
        this.shiftId = json.shiftId;
        this.shiftDate = json.shiftDate ? new Date(json.shiftDate) : null;
        this.shiftTypeId = json.shiftTypeId;
        this.startTime = json.startTime ? new Date(json.startTime) : new Date(1900, 0, 1, 0, 0, 0);
        this.endTime = json.endTime ? new Date(json.endTime) : new Date(1900, 0, 1, 0, 0, 0);
        this.unit = json.unit;
        this.lunch = json.lunch;
        this.timesheetId = json.timesheetId;
    }
}
