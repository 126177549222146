import AllJobFiltersForm from "../all-job-filters-form/AllJobFiltersForm";
import "./AllJobFiltersContainerMobile.css";
import xIcon from "../assets/x.png";
import ExternalJobSearchForm from "../external-job-search-form/ExternalJobSearchForm";
import { useState } from "react";

type Props = {
  professionId?: string | null;
  specialtyIds?: string | null;
  stateIds?: string | null;
  cityIds?: string | null;
  shiftCategoryId?: string | null;
  numberOfShifts?: string | null;
  showFiltersContainer: boolean;
  onToggle: () => void;
  searchPageUrl?: string;
  titleText?: string;
};

export default function AllJobFiltersContainerMobile(props: Props) {
  const [titleText] = useState(props.titleText ?? "Filters");
  
  return (
    <>
      {/* <div
        className="filterContainerMobileBackground"
        onClick={() => {
          props.onToggle();
        }}
      ></div> */}
      <div
        className="filterContainerMobileRoot"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="filterTitle">
          <h2>{titleText}</h2>
          <img
            alt="Close Filters"
            className="x-button"
            src={xIcon}
            onClick={() => {
              props.onToggle();
            }}
          />
        </div>
        {!props.searchPageUrl &&
          <AllJobFiltersForm
            professionId={props.professionId ?? null}
            specialtyIds={props.specialtyIds ?? null}
            stateIds={props.stateIds ?? null}
            cityIds={props.cityIds ?? null}
            shiftCategoryId={props.shiftCategoryId ?? null}
            numberOfShifts={props.numberOfShifts ?? null}
          ></AllJobFiltersForm>
        }
        {props.searchPageUrl &&
          <ExternalJobSearchForm form_submit_url={props.searchPageUrl} onMultiSelectHide={() => {return}} onMultiSelectShow={() => {return}}></ExternalJobSearchForm>
        }
      </div>
    </>
  );
}
