import { TimesheetShiftData } from "../tp-core-types/Timesheet";
import TimesheetShift from "./TimesheetShift"; 
import "../Timesheet/TimesheetShifts.scss";
import { useEffect, useState } from "react";
import plusSign from "../assets/plus_sign.svg";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";

type Props = {
    title: string,
    submissionDate: string|null,
    shifts: Array<TimesheetShiftData>,
    timesheetId: number,
    payPeriodStartDate: Date,
    payPeriodEndDate: Date,
    payPeriodLocked: boolean,     
}

const getIsMobile = (): boolean => {
    return window.matchMedia("(max-width: 1250px)").matches;
}

export default function TimesheetShifts(props: Props) {
    const [timesheetId, setTimesheetId] = useState<number>(props.timesheetId);
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const [shifts, setShifts] = useState(props.shifts); 
    const [showNewTimeEntryButton, setShowNewTimeEntryButton] = useState(!props.payPeriodLocked);

    useEffect(() => {
        setTimesheetId(props.timesheetId);
    },[props.timesheetId])

    useEffect(()=>{
        setShifts(props.shifts); 
        setShowNewTimeEntryButton(!props.payPeriodLocked);

    },[props.shifts, props.payPeriodLocked ]) 

    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);
        //clean up after the component unmounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])
 
    function createNewRecord(){
        setShowNewTimeEntryButton(false);
        setShifts([{shiftId: null, shiftDate: null, shiftTypeId: 0, startTime: null, endTime: null, unit: "", lunch: null, timesheetId: timesheetId}, ...shifts]);
    }    
    
    async function deleteShiftEntry(delShiftId: number |null ){         
        const index = shifts.findIndex((x) => x.shiftId == delShiftId);
        const foundShift = shifts[index];
        setShifts([...shifts.slice(0,index), ...shifts.slice(index+1)]);

        //special case for cancelling edit mode...
        if(delShiftId == null) {
            setShowNewTimeEntryButton(true);
        }
    }
    function saveShift(updateShift: TimesheetShiftData)
    {  
         //create new shift array.  replace first array with the updated value
        setShifts([updateShift, ...shifts.slice(1)]);
        setShowNewTimeEntryButton(true);

    }
    return (
        <>
        <div id="TimesheetShifts"> 
        {!isMobile && props.submissionDate !== "" && props.submissionDate !== null &&
            <div className="timesheet-header">
                <div className="check-container">
                    <div className="check"></div> 
                </div>
                <div>Submitted {props.submissionDate}</div>
            </div>
        }
       {!isMobile &&
            <div className="timesheet-shifts-title">{props.title}<div className='timesheet-shifts-title-bottom-border'></div></div>
       }
        {shifts.length > 0 &&
        <>
            {showNewTimeEntryButton && 
                <div className="new-record-button-content">
                    <button className="new-time-button" onClick={createNewRecord} data-testid="timesheetShifts-btnNewTimeEntry">                     
                        <img src={plusSign} alt=""/>
                        <div>New Time Entry</div>                    
                    </button>
                </div>
            }
            
            <div className="TimesheetShifts">
                <div className="timesheet-shifts-data">
                    {shifts.map((s: TimesheetShiftData) => 
                    { return <TimesheetShift 
                            key= {s.shiftId} 
                            shiftData={s} payPeriodStartDate={props.payPeriodStartDate} payPeriodEndDate={props.payPeriodEndDate}                             
                            payPeriodLocked={props.payPeriodLocked} 
                            saveCompletedCallback={(s) => {saveShift(s) }}  
                            deleteCompletedCallback= {(shiftId) => {deleteShiftEntry(shiftId) }}   /> 
                    }
                )}
                </div>
            </div> 
        </>
        }
        {/* will not show inputs if its locked and no shifts */}
 
        {!props.payPeriodLocked  && shifts.length === 0 && 
        <TimesheetShift shiftData={{shiftId: null, shiftDate: null, shiftTypeId: 0, startTime: null, endTime: null, 
                                        unit: "", lunch: null, timesheetId: timesheetId}} 
            payPeriodStartDate={props.payPeriodStartDate} payPeriodEndDate={props.payPeriodEndDate}           
            payPeriodLocked={props.payPeriodLocked}
            saveCompletedCallback={(shiftData) => {saveShift(shiftData) }}  
            deleteCompletedCallback= {(shiftId) => {deleteShiftEntry(shiftId) }} 
            />

        }
   </div> </>
    )
}